var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mb-2 mx-0" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0 pr-1 pt-1", attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: _vm.$t("message.common.search"),
                  "hide-details": "",
                  "single-line": "",
                  outlined: "",
                  dense: "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "px-1 mt-1", attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c("compact-list", {
                key: _vm.reInit,
                attrs: { payload: _vm.payload },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "custom-margin", width: "500" },
          model: {
            value: _vm.showTaskDetailsDialog,
            callback: function ($$v) {
              _vm.showTaskDetailsDialog = $$v
            },
            expression: "showTaskDetailsDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "my-auto", attrs: { "min-height": "100px" } },
            [
              _vm.gettingTaskLoader
                ? _c(
                    "p",
                    { staticClass: "text-center mb-0 pt-3" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: 70,
                          width: 7,
                          color: "purple",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.selectedTask
                ? _c(
                    "v-form",
                    { ref: "taskInfoForm" },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "headline grey lighten-2 pa-2",
                          attrs: { "primary-title": "" },
                        },
                        [
                          _c("p", { staticClass: "mb-0" }, [
                            _vm._v(
                              _vm._s(_vm.$t("message.tasks.forYour")) + " "
                            ),
                            _vm.selectedTask.action
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "message.tasks." +
                                          _vm.modalTitleValues.find(function (
                                            x
                                          ) {
                                            return (
                                              x.action ===
                                              _vm.selectedTask.action
                                            )
                                          }).text
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 mb-1",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("message.timer.from")) +
                                        ": "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "8" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedTask.revision.owner_name || ""
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 mb-1",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("message.tasks.subject")) +
                                        ": "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "8" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedTask.revision.subject || ""
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 mb-1",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("message.common.date")) +
                                        ": "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "8" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedTask.revision.created_at
                                        ? _vm.$formatter.formatDate(
                                            _vm.selectedTask.revision
                                              .created_at,
                                            "YYYY-MM-DD",
                                            "DD.MM.YYYY"
                                          )
                                        : ""
                                    )
                                  ),
                                ]
                              ),
                              !_vm.selectedTask.owntask &&
                              _vm.selectedTask.revision.escalation_date
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 mb-1",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("message.tasks.dueDate")
                                          ) + ": "
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.selectedTask.owntask &&
                              _vm.selectedTask.revision.escalation_date
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedTask.revision.due_date
                                            ? _vm.$formatter.formatDate(
                                                _vm.selectedTask.revision
                                                  .due_date,
                                                "YYYY-MM-DD",
                                                "DD.MM.YYYY"
                                              )
                                            : ""
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 mb-1",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("message.deviation.description")
                                      ) + ": "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "8" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.selectedTask.revision.description
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 mb-1",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("message.deviation.document")
                                      ) + ": "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "8" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.selectedTask
                                          .document_download_url,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedTask.document
                                            .original_name || ""
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 mb-1",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("message.mixed.sharedUrl")
                                      ) + ": "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "8" } },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.selectedTask.revision
                                          .shared_url,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedTask.revision
                                            .shared_url || ""
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 mb-1",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("message.common.comments")
                                      ) + ": "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c("vue-editor", {
                                    model: {
                                      value: _vm.selectedTask.comment,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedTask,
                                          "comment",
                                          $$v
                                        )
                                      },
                                      expression: "selectedTask.comment",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pa-0", attrs: { cols: "12" } },
                                [
                                  _c("v-file-input", {
                                    attrs: {
                                      name: "documents",
                                      label: _vm.$t(
                                        "message.deviation.document"
                                      ),
                                    },
                                    model: {
                                      value: _vm.documents,
                                      callback: function ($$v) {
                                        _vm.documents = $$v
                                      },
                                      expression: "documents",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          this.selectedTask.action === 1
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      small: "",
                                      color: "primary",
                                      loading: _vm.updateLoaderObj.approve,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateRevision(1, "approve")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("message.tasks.approve"))
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      small: "",
                                      color: "info",
                                      loading: _vm.updateLoaderObj.disapprove,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateRevision(
                                          2,
                                          "disapprove"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("message.tasks.disapprove"))
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          this.selectedTask.action === 3
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      small: "",
                                      color: "primary",
                                      loading: _vm.updateLoaderObj.acknowledge,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateRevision(
                                          4,
                                          "acknowledge"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("message.tasks.acknowledge")
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          this.selectedTask.action === 4
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      small: "",
                                      color: "primary",
                                      loading: _vm.updateLoaderObj.keep,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateRevision(7, "keep")
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("message.tasks.keep")))]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      small: "",
                                      color: "info",
                                      loading: _vm.updateLoaderObj.remove,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateRevision(5, "remove")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("message.tasks.remove"))
                                    ),
                                  ]
                                ),
                              ]
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", color: "error" },
                              on: {
                                click: function ($event) {
                                  ;(_vm.showTaskDetailsDialog = false),
                                    (_vm.selectedTask = null)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("message.common.close")))]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }